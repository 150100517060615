import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { db } from './firebase-config';
import { doc, onSnapshot, setDoc } from '@firebase/firestore';

const initialCategories = [];

const generateRandomNumber = () => {
  return Math.floor(10000 + Math.random() * 90000); // Generates a random 5-digit number
};

const RecursiveTreeView = ({ nodes, addNode, deleteNode, openEditModal, depth = 0 }) => {
  if (!nodes || nodes.length === 0) {
    return null;
  }
useEffect(()=>{
  console.log(nodes)
},[])
  return nodes.map((node) => (
    <TreeItem
      key={node.id}
      itemId={node.id}
      nodeId={node.id}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{node.name}</Typography>
          {depth < 2 && (
            <IconButton
              aria-label="add"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                addNode(node.id);
              }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          )}
          <IconButton
            aria-label="edit"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              openEditModal(node.id, node.name);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              deleteNode(node.id);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      }
    >
      {node.subcategories && node.subcategories.length !== 0 && (
        <RecursiveTreeView
          nodes={node.subcategories}
          addNode={addNode}
          deleteNode={deleteNode}
          openEditModal={openEditModal}
          depth={depth + 1}
        />
      )}
    </TreeItem>
  ));
};

export default function BasicSimpleTreeView() {
  const [categories, setCategories] = useState(initialCategories);
  const [loading, setLoading] = useState(true);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editNodeId, setEditNodeId] = useState('');
  const [editNodeName, setEditNodeName] = useState('');
  const [categoryModalOpen, setCategoryModalOpen] = useState(false);

  const openEditModal = (id, name) => {
    setEditNodeId(id);
    setEditNodeName(name);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setEditNodeId('');
    setEditNodeName('');
  };

  const categoriesRef = doc(db, 'categories', 'categoriesDoc');

  useEffect(() => {
    const unsubscribe = onSnapshot(categoriesRef, (doc) => {
      if (doc.exists()) {
        setCategories(doc.data().categories || initialCategories);
      } else {
        setCategories(initialCategories);
      }
     
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const addRootCategory = async () => {
    const newId = `${generateRandomNumber()}-${new Date().getTime()}`;
    const newName = newCategoryName || 'New Category';
    const newCategories = [...categories, { id: newId, name: newName, subcategories: [] }];
    setCategories(newCategories);
    await writeCategoriesToDatabase(newCategories);
    setNewCategoryName('');
  };

  const addNode = async (parentId) => {
    const newId = `${generateRandomNumber()}-${new Date().getTime()}`;
    const newName = 'New Subcategory';
    const addSubcategory = (nodes) => {
      return nodes.map((node) => {
        if (node.id === parentId) {
          return {
            ...node,
            subcategories: [...(node.subcategories || []), { id: newId, name: newName }],
          };
        } else if (node.subcategories) {
          return {
            ...node,
            subcategories: addSubcategory(node.subcategories),
          };
        }
        return node;
      });
    };

    const newCategories = addSubcategory(categories);
    setCategories(newCategories);
    await writeCategoriesToDatabase(newCategories);
    console.log(categories);
  };

  const deleteNode = async (nodeId) => {
    const removeNode = (nodes) => {
      return nodes
        .filter((node) => node.id !== nodeId)
        .map((node) => {
          if (node.subcategories) {
            return { ...node, subcategories: removeNode(node.subcategories) };
          }
          return node;
        });
    };

    const updatedCategories = removeNode(categories);
    setCategories(updatedCategories);
    await writeCategoriesToDatabase(updatedCategories);
  };

  const editNode = async (id, newName) => {
    const updateNode = (nodes) => {
      return nodes.map((node) => {
        if (node.id === id) {
          return { ...node, name: newName };
        } else if (node.subcategories) {
          return { ...node, subcategories: updateNode(node.subcategories) };
        }
        return node;
      });
    };

    const updatedCategories = updateNode(categories);
    setCategories(updatedCategories);
    await writeCategoriesToDatabase(updatedCategories);
  };

  const saveEditedNode = async () => {
    const updateNode = (nodes) => {
      return nodes.map((node) => {
        if (node.id === editNodeId) {
          return { ...node, name: editNodeName };
        } else if (node.subcategories) {
          return { ...node, subcategories: updateNode(node.subcategories) };
        }
        return node;
      });
    };

    const updatedCategories = updateNode(categories);
    setCategories(updatedCategories);
    await writeCategoriesToDatabase(updatedCategories);

    closeEditModal();
  };

  const writeCategoriesToDatabase = async (categories) => {
    try {
      await setDoc(categoriesRef, { categories });
      console.log('Categories successfully written to Firestore!');
    } catch (error) {
      console.error('Error writing categories to Firestore: ', error);
    }
  };
  // const categoriesRef = doc(db, 'categories', 'categoriesDoc');

function addIdsToCategories(categories) {
  return categories.map(category => {
    const categoryId = generateId();
    return {
      id: categoryId,
      name: category.name,
      subcategories: category.subcategories ? addIdsToSubcategories(category.subcategories) : []
    };
  });
}

function addIdsToSubcategories(subcategories) {
  return subcategories.map(subcategory => {
    const subcategoryId = generateId();
    return {
      id: subcategoryId,
      name: subcategory.name,
      subcategories: subcategory.subcategories ? addIdsToSubcategories(subcategory.subcategories) : []
    };
  });
}

function generateId() {
  return Math.random().toString(36).substr(2, 9); // Generates a random ID
}


  
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{}}>
      {/* <button onClick={async()=>{

const categoriesWithIds = addIdsToCategories(categoriesData.categories);


  try {
    await setDoc(categoriesRef, { categories: categoriesWithIds });
    console.log('Categories updated successfully');
  } catch (error) {
    console.error('Error updating categories:', error);
  }

      
      }}>test</button>*/}
      <Button 
        variant="contained"
        color="primary"
        size='small'
        onClick={() => setCategoryModalOpen(true)}
        sx={{ marginBottom: 2 }}
      >
        Edit Categories
      </Button>
      <Dialog open={categoryModalOpen} onClose={() => setCategoryModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Edit Categories</DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addRootCategory}
            sx={{ marginBottom: 2 }}
          >
            Add Root Category
          </Button>
          <SimpleTreeView>
            <RecursiveTreeView nodes={categories} addNode={addNode} deleteNode={deleteNode} openEditModal={openEditModal} />
          </SimpleTreeView>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCategoryModalOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editModalOpen} onClose={closeEditModal}>
        <DialogTitle>Edit Category</DialogTitle>
        <DialogContent>
          <TextField
            label="Category Name"
            value={editNodeName}
            onChange={(e) => setEditNodeName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditModal} color="primary">
            Cancel
          </Button>
          <Button onClick={saveEditedNode} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
