import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { IconButton, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

export const options = [
  { label: "TVs", iconClass: "fas fa-tv",
    children: [
      {
        title: 'TVs by Brand',
        children: ['Von', 'LG', 'Samsung', 'Hisense'],
      },
      {
        title: 'TVs by Size',
        children: ["24'' to 32''", "40'' to 49''", "50'' to 59''", "60'' to 70''", "75'' & Above"],
      },
      {
        title: 'TVs By Feature',
        children: ['Digital', 'Smart', 'HD', 'FHD', '4K UHD', 'QLED', 'QNED', 'OLED', 'Nanocell', '8K'],
      },
      {
        title: 'Projectors',
        children: ['Portable'],
      },
      {
        title: 'Accessories',
        children: ['TV Brackets'],
      },
    ],
  },
  { label: "Audio", iconClass: "fas fa-volume-up",
    children: [
      {
        title: 'Audio Type',
        children: ['Soundbars', 'Noise Cancelling', 'Home Theatre Systems', 'Subwoofers', 'Earbuds', 'Gaming', 'Headphones', 'Portable Speakers'],
      },
      {
        title: 'Audio By Brand',
        children: ['LG', 'Samsung', 'Von', 'JBL', 'Oraimo', 'Sony', 'Belkin', 'Hisense', 'Anker', 'Soundcore', 'Harman Kardon'],
      },
    ],
  },
  { label: "Fridges & Freezers", iconClass: "fas fa-snowflake",
    children: [
      {
        title: 'Fridges',
        children: ['Bespoke', 'Solar', 'InstaView', 'Mini', 'Single Door', 'Double Door (Top Mount Freezer)', 'Combination (Bottom Mount Freezer)', 'Side by Side', 'Upright'],
      },
      {
        title: 'Freezers',
        children: ['Chest', 'Upright'],
      },
      {
        title: 'Chillers',
        children: ['Wine Coolers', 'Vertical Coolers'],
      },
    ],
  },
  { label: "Washers & Dryers", iconClass: "fas fa-tshirt",
    children: [
      {
        title: 'Washing Machines',
        children: ['Twin Tubs', 'Top Loaders', 'Front Loaders', 'Washer & Dryers', 'Commercial'],
      },
      {
        title: 'Washers & Dryers',
        children: ['WashTower', 'Front Loaders'],
      },
      {
        title: 'Dryers',
        children: ['Condensing', 'Commercial'],
      },
      {
        title: 'Dishwasher',
        children: ['12 Place Setting', '14 Place Setting'],
      },
      {
        title: 'Accessories',
        children: ['Cleaning Products'],
      },
    ],
  },
  { label: "Cookers & Ovens", iconClass: "fas fa-utensils",
    children: [
      {
        title: 'Free Standing Cookers',
        children: ['Electric Oven', 'Gas Oven'],
      },
      {
        title: 'Size (Width)',
        children: ['50 - 55cm', '56 - 60cm', '65 - 90cm', '100cm'],
      },
      {
        title: 'Burners',
        children: ['4 Gas', '5 Gas', '6 Gas', '4 Electric', '3 Gas + 1 Electric', '4 Gas + 2 Electric'],
      },
      {
        title: 'Table Top Cookers',
        children: ['Gas Burners', 'Gas + Electric Burners', 'Electric Burners'],
      },
      {
        title: 'Ovens',
        children: ['20 - 40L', '70 - 90L'],
      },
      {
        title: 'Microwave Ovens',
        children: ['Standard', 'Oven & Grill', 'Oven, Grill & Convection Combos'],
      },
      {
        title: 'Cleaning Products',
        children: [],
      },
    ],
  },
  { label: "Kitchen & Small Home Appliances", iconClass: "fas fa-blender",
    children: [
      {
        title: 'Kitchen Essentials',
        children: ['Coffee Makers', 'Kettles', 'Toasters', 'Blenders', 'Juicers', 'Sandwich Makers', 'Waffle Makers'],
      },
      {
        title: 'Small Cooking Appliances',
        children: ['Air Fryers', 'Deep Fryers', 'Rice Cookers', 'Slow Cookers', 'Pressure Cookers', 'Popcorn Poppers', 'Egg Cookers', 'Barbecue Grills'],
      },
      {
        title: 'Food Preparation',
        children: ['Hand Mixers', 'Food Processors', 'Food Choppers & Grinders', 'Kitchen Utensils', 'Hand Blenders', 'Meat Mincers', 'Stand Mixers', 'Kitchen Machines', 'Kitchen Weighing Scales'],
      },
      {
        title: 'Baking & Cookware',
        children: ['Bread Makers'],
      },
      {
        title: 'Water Dispensers',
        children: ['Bottom Loading', 'Touchless Dispensers', 'Portable Dispensers', 'Compressor Cooling', 'Electric Cooling', 'Water Purifiers', 'Hot & Normal', 'Normal (Non-Electric)'],
      },
      {
        title: 'Garment Care',
        children: ['Dry Irons', 'Steam Irons', 'Steam Stations', 'Garment Steamers', 'Ironing Boards'],
      },
      {
        title: 'Fans',
        children: ['Air Coolers', 'Air Purifiers', 'Standing Fans', 'Wall Fans'],
      },
    ],
  },
  { label: "Built in Appliances", iconClass: "fas fa-building",
    children: [
      { title: 'Ovens', children: [] },
      { title: 'Hobs', children: [] },
      { title: 'Microwaves', children: [] },
      { title: 'Hoods', children: [] },
      { title: 'Dishwashers', children: [] },
      { title: 'Fridges & Freezers', children: [] },
      { title: 'Warmer Drawer', children: [] },
    ],
  },
  { label: "AC's, Fans & Heaters", iconClass: "fas fa-fan",
    children: [
      {
        title: 'Air Conditioners',
        children: ['Split Air Conditioners', 'Portable Air Conditioners', 'Cassette Air Conditioners', 'Ceiling Concealed Duct Air Conditioners'],
      },
      {
        title: 'Fans',
        children: ['Standing Fans', 'Air Coolers', 'Air Purifiers', 'Wall Fans'],
      },
      {
        title: 'Heaters',
        children: ['Ceramic Heaters', 'Convection Heaters', 'Fan Heaters', 'Oil Radiator Heaters', 'Quartz Heaters'],
      },
    ],
  },
  { label: "Health & Personal Care", iconClass: "fas fa-heartbeat",
    children: [
      {
        title: 'Health',
        children: ['Thermometers', 'Weighing Scales'],
      },
      {
        title: 'Personal Care',
        children: ['Hair Care', 'Shave & Hair Removal'],
      },
      {
        title: 'Weighing Scales',
        children: ['Medical'],
      },
    ],
  },
  { label: "Accessories", iconClass: "fas fa-plug",
    children: [
      {
        title: 'TV & Audio',
        children: ['Microphones', 'TV Brackets', 'Voltage Protectors', 'Cables'],
      },
      {
        title: 'Mobile Accessories',
        children: ['Car Chargers', 'Power Adapters', 'Power Banks', 'Hub Adapters', 'Charging Cables', 'Wireless Stand Chargers'],
      },
      {
        title: 'Fridges & Freezers',
        children: ['Voltage Protectors', 'Cleaning Products'],
      },
      {
        title: 'Washers & Dryers',
        children: ['Voltage Protectors', 'Cleaning Products'],
      },
    ],
  },
  { label: "Home Cleaning", iconClass: "fas fa-broom",
    children: [
      {
        title: 'Vacuum Cleaners',
        children: ['Robovac', 'Handheld', 'Canister Type'],
      },
    ],
  },
  { label: "Phones & Tablets", iconClass: "fas fa-mobile-alt",
    children: [
      {
        title: 'Smart Watches',
        children: ['Oraimo OSW-30', 'Oraimo OSW-34'],
      },
      {
        title: 'Samsung Z series',
        children: ['Samsung Z Fold 5 - 512GB', 'Samsung Z Flip 5 - 256GB'],
      },
      {
        title: 'Samsung A Series',
        children: ['Samsung A05', 'Samsung A04', 'Samsung A34 5G', 'Samsung A14 4GB', 'Samsung A24 4GB', 'Samsung A14 5G', 'Samsung A04S'],
      },
      {
        title: 'Samsung S Series',
        children: ['Samsung S23 Ultra', 'Samsung S23', 'Samsung S23 Plus'],
      },
      {
        title: 'Samsung Tablets',
        children: ['Samsung Tab A8 32GB', 'Samsung Tab A8 64GB'],
      },
    ],
  },
  { label: "Mobile & Computer", iconClass: "fas fa-laptop",
    children: [
      {
        title: 'Laptops',
        children: ['HP'],
      },
      {
        title: 'Accessories',
        children: ['Type-C Power Adapter', 'Extension Cable', 'Magsafe Charger'],
      },
    ],
  },
  { label: "Promotions", iconClass: "fas fa-tags",
    children: [
      {
        title: 'Red',
        children: ['LG'],
      },
      {
        title: 'Green',
        children: ['Samsung'],
      },
    ],
  },
];
const menuItemStyle = {
  display: 'flex',
  position: 'relative',
  alignItems: 'center', // Center icon and text vertically
  cursor: 'pointer', // Add pointer cursor
  padding: '8px', // Add padding for better spacing
  transition: 'background-color 0.3s', // Smooth transition for background color
};

const iconStyle = {
  marginRight: '10px', // Add spacing between icon and text
};

const subMenuIconStyle = {
  marginLeft: 'auto', // Push the forward icon to the right
};

const subMenuStyle = {
  width: '300px', // Adjust the submenu width as needed
  backgroundColor: 'white',
  color:'black',
  padding:10,
  top: 0,
  right: '-320px', // Hide the submenu by default
  position: 'absolute',
  boxShadow: '0 1px 1.5px 0 rgba(0,0,0,.12), 0 1px 1px 0 rgba(0,0,0,.24)',
  zIndex: 1000,
  
  // Make sure it's on top of other elements
  // transition: 'right 0.3s', // Add a smooth transition for showing/hiding
};

export default function App() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const [hoverIndex, setHoverIndex] = useState(1);
  const open = Boolean(anchorEl);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMouseEnter = (index) => {
    // Show the submenu on hover
    setDrawerOpen(true);
    setHoverIndex(index)
  };

  const handleMouseLeave = () => {
    // Hide the submenu when the mouse leaves
    setDrawerOpen(false);
  };
const navigate=useNavigate()
  return (
    <div className='bannerMain'>
      <div className='categoryBanner'>
        <div className='catMenu largeDevice'>
          <List>
            {options.map((option, index) => (
              <div key={option.label}>
                <ListItem
                  button
                  key={option.label}
                  selected={index === selectedIndex}
                  onClick={(event) =>{
                    navigate('/category?search='+option.label.split(' ')[0])
                    // handleMenuItemClick(event, index)
                  
                  }}
                  onMouseEnter={()=>handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  style={menuItemStyle}
                  sx={{
                    '&:hover': {
                      color: 'white',
                      background: 'linear-gradient(45deg, #FF512F 30%, #DD2476 90%)',
                    },
                  }}
                >
                  <i className={option.iconClass} style={iconStyle}></i> {option.label}
                {/* {option.children && option.children.length>0 &&  <ChevronRightIcon style={subMenuIconStyle} />} */}
                {/* {option.children && option.children.length>0 && hoverIndex==index && drawerOpen && (
                  <div style={subMenuStyle} >
                  {option.children.map(item=>{
                    return <>
<strong>
  {item.title}
</strong>
                    {item.children.map(opt=><Typography className='menuitem'>{opt}</Typography>)}
                    </>
                  })}
                  </div>
                )} */}
                </ListItem>
              
              </div>
            ))}
          </List>
        </div>
        <div style={{ flex: 1,display:'flex',alignItems:'center',justifyContent:'space-between',flexDirection:'row' ,flexWrap:'wrap'}}>
        <div style={{minWidth:350,flex:3,backgroundColor:'white',display:'flex',flexDirection:"column"}}>
<div style={{height:'65%',minHeight:350,backgroundImage: 'url(https://www.corkyspest.com/wp-content/uploads/2016/11/Electronics-Banner-2-1.jpg)',backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: "no-repeat" ,margin:2,borderRadius:15,position:'relative',overflow:'hidden'}}>

<div style={{position:'absolute',maxWidth:'70%',borderRadius:8,padding:10,right:10,bottom:10,backgroundColor:'rgba(222, 38, 114,0.8)'}}>
    <Typography variant='h7' style={{fontWeight:'bold',color:'white'}}>GET THE BEST DEALS WITH DENFA TECHNOLOGIES!</Typography><br/>
    <Typography variant='title' style={{fontWeight:'normal',color:'white'}}>we offer the best deals in all types of products, begin shopping today!</Typography>
 
  </div>
</div>
<div style={{flex:1,backgroundColor:'white',display:'flex'}}>
<div style={{minHeight:150,backgroundImage: 'url(https://www.shutterstock.com/image-photo/4k-monitor-watching-smart-tv-260nw-635404505.jpg)',backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: "no-repeat",width:'35%',margin:2,minWidth:100,borderRadius:15,position:'relative',overflow:'hidden'}}>

<div style={{position:'absolute',padding:10,left:0,bottom:0,width:'100%',backgroundColor:'rgba(0,0,0,0.7)'}}>
    <Typography variant='h7' style={{fontWeight:'normal',color:'white'}}>TV</Typography>
  </div>
</div>
<div style={{minHeight:150,backgroundImage: 'url(https://i.pinimg.com/originals/07/b4/27/07b427c6bfabfb5d663efaaf05392b0c.png)',backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: "no-repeat",flex:1,margin:2,minWidth:100,borderRadius:15,position:'relative',overflow:'hidden'}}>\


<div style={{position:'absolute',padding:10,left:0,bottom:0,width:'100%',backgroundColor:'rgba(0,0,0,0.7)'}}>
    <Typography variant='h7' style={{fontWeight:'normal',color:'white'}}>MOBILE PHONES</Typography>
  </div>
</div>

</div>
        </div>
        <div style={{flex:2,minWidth:350,backgroundColor:'white',display:'flex',flexDirection:"column"}}>
<div style={{flex:1,minHeight:250,overflow:'hidden',backgroundImage: 'url(https://i.pinimg.com/736x/f0/f9/e4/f0f9e45724771f16745ad3f6f640d3ce.jpg)',backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: "no-repeat",margin:2,borderRadius:15,position:'relative'}}>
<div style={{position:'absolute',padding:10,left:0,bottom:0,width:'100%',backgroundColor:'rgba(0,0,0,0.7)'}}>
    <Typography variant='h7' style={{fontWeight:'normal',color:'white'}}>ElECTRONIC OFFERS</Typography>
  </div>
</div>
<div style={{flex:1,minHeight:250,overflow:'hidden',backgroundImage: 'url(https://png.pngtree.com/thumb_back/fw800/background/20230715/pngtree-aluminum-made-dvd-receiver-and-home-theater-system-with-subwoofer-and-image_3867351.jpg)',backgroundPosition: 'center center', backgroundSize: 'cover', backgroundRepeat: "no-repeat",margin:2,borderRadius:15,position:'relative'}}>
<div style={{position:'absolute',padding:10,left:0,bottom:0,width:'100%',backgroundColor:'rgba(0,0,0,0.7)'}}>
    <Typography variant='h7' style={{fontWeight:'normal',color:'white'}}>SPEAKERS</Typography>
  </div>

</div>
        </div>
        </div>
      </div>
    </div>
  );
}
