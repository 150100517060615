// app/rootReducer.js

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './features/user/userSlice';
import cartReducer from './features/cart/cartSlice';

const cartPersistConfig = {
  key: 'cart',
  storage,
};

const userPersistConfig = {
  key: 'user',
  storage,
};

const rootReducer = combineReducers({
  cart: persistReducer(cartPersistConfig, cartReducer),
  user: persistReducer(userPersistConfig, userReducer)
});

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: ['cart', 'user']
};

export default persistReducer(rootPersistConfig, rootReducer);
