import { initializeApp } from "firebase/app";
import {getFirestore} from "@firebase/firestore"
import { getStorage } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyDetv78oprg5oGN_ueB_LTTcQXfcxY3In0",
  authDomain: "denfa-commerce.firebaseapp.com",
  projectId: "denfa-commerce",
  storageBucket: "denfa-commerce.appspot.com",
  messagingSenderId: "621452445068",
  appId: "1:621452445068:web:3b6cf96ca481cfc40782a9",
  measurementId: "G-KKM8G4LW6F"
};

const app=initializeApp(firebaseConfig);
export const db=getFirestore(app);
export const storage = getStorage(app);
