import React, { useEffect, useState } from 'react';
import { Pagination as MuiPagination, Stack } from '@mui/material';
import BottomTabs from './BottomTabs';
import Navbar from './navbar.js';
import { Typography } from '@mui/material';
import { Rating } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { db } from './firebase-config';
import { collection, getDocs } from '@firebase/firestore';
import { useNavigate, useSearchParams } from 'react-router-dom';

// Fisher-Yates shuffle algorithm
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export default function Category() {
  const [productList, setProductList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { category } = useParams();
  const initialPage = parseInt(searchParams.get('page')) || 1;
  const initialSearchTerm = searchParams.get('search') || '';
  const initialSearchProductTerm = searchParams.get('searchProduct') || '';
  const [currentPage, setCurrentPage] = useState(initialPage);
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const [categories, setCategories] = useState([]);

  const productsPerPage = 20;

  // Fetch products from Firestore
  const fetchProducts = async () => {
    const data = await getDocs(collection(db, 'products'));
    let products = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));

    // Apply category filter if present
 
    // if (category) {
    //   products = products.filter(product => product.category.toLowerCase() === category.toLowerCase());
    // }
  var srch=search;
    // Apply search term filter if present
    if(srch.trim()==''){
      srch=initialSearchProductTerm.toLowerCase();
      
    }
    if (searchTerm.trim() !== '' && srch.trim()=='') {
      const regex = new RegExp(searchTerm.trim(), 'i');
      products = products.filter(item => item.category.match(regex));
    }else   if (searchTerm.trim() !== '' && srch.trim()!='') {
    
    const regex = new RegExp(searchTerm.trim(), 'i');
    const regex2 = new RegExp(srch.trim(), 'i');
    products = products.filter(item => item.category.match(regex) && item.name.match(regex2) );
  
  }else{

    const regex2 = new RegExp(srch.trim(), 'i');
    products = products.filter(item => item.name.match(regex2));
  }
  
    // Shuffle products
    products = shuffleArray(products);
    setProductList(products);
  };
  const [search,setSearch]=useState('');
  const searchbyterm=async ()=>{
  
  }
useEffect(()=>{

  fetchProducts();
  paginate(1)
},[search])
  // Fetch products and categories initially
  useEffect(() => {
  
    fetchProducts();
  }, []);

  // Fetch products when category or search term changes
  useEffect(() => {
    fetchProducts();
  }, [category, searchTerm,search]);

  // Change page without affecting category or search term
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set('page', pageNumber.toString()); // Update only the 'page' parameter
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    window.scrollTo(0, 0);
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setSearchParams(new URLSearchParams({ ...searchParams, search: event.target.value }));
    navigate(`?${new URLSearchParams({ ...searchParams, search: event.target.value }).toString()}`);
  };


  // Render UI
  return (
    <div>

      <Navbar  setSearch2={setSearch} search2={search}/>
      <Typography variant='h6' style={{ padding: 10, backgroundColor: '#f8f8f8', fontWeight: 'bold' }}>
        {window.location.pathname=='/'?'Search':'Category'}
        : {category}</Typography>
      <div style={{display:"flex",width:"100vw",justifyContent:"center"}}>
        {/* <input
          type="text"
          placeholder="Search by product name..."
          value={searchTerm}
          onChange={handleSearchChange}
          style={{ padding: '8px', fontSize: '16px', width: '100%', maxWidth: '400px', margin: '0 auto', display: 'block' }}
        /> */}
        <select
    value={searchTerm}
    onChange={handleSearchChange}
          // value={category}
          // onChange={(e) => {navigate(`?category=${e.target.value}&search=${searchTerm}`)}}
          style={{ padding: '8px', fontSize: '16px', flex:1, maxWidth: '400px',display: 'block' }}
        >
 
            <option value="">All Categories</option>
  <option value="tvs">TVs</option>
  <option value="audio">Audio</option>
  <option value="fridges">Fridges &amp; Freezers</option>
  <option value="washers">Washers &amp; Dryers</option>
  <option value="Cookers">Cookers &amp; Ovens</option>
  <option value="kitchen">Kitchen &amp; Small Home Appliances</option>
  <option value="appliances">Built in Appliances</option>
  <option value="ac-fans-heaters">AC's, Fans &amp; Heaters</option>
  <option value="health">Health &amp; Personal Care</option>
  <option value="accessories">Accessories</option>
  <option value="cleaning">Home Cleaning</option>
  <option value="phones">Phones &amp; Tablets</option>
  <option value="computer">Mobile &amp; Computer</option>

        </select>
      </div>
      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', maxWidth: 1200 }}>
        {productList.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage).map(item =>
  <Link target="_blank" rel="noopener noreferrer" to={`../../product/${item.id}/${item.name.trim().replaceAll("-", " ").replaceAll(/\s+/g, " ").replaceAll(" ", "-").split('/')[0]}`} key={item.id} style={{ width: '48%', textDecoration: 'none', maxWidth: 200, minWidth: 170, margin: 3, border: '0.3px solid #e5e5e5', backgroundColor: '#f8f8f8', position: 'relative', display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', minHeight: 170 }}>
      <img src={item?.images[0]} style={{ maxHeight: 200, width: '100%', maxWidth: 200, minWidth: 170, objectFit: 'contain', objectPosition: 'center' }} alt={item.name} />
    </div>
    {((item.originalPrice - item.price) / item.originalPrice * 100) >= 1 && <div style={{ padding: 3, fontSize: 12, borderRadius: 10, backgroundColor: 'red', color: 'white', opacity: 0.8, fontWeight: 'bold', position: 'absolute', top: 10, right: 5, zIndex: 10 }}>
      {((item.originalPrice - item.price) / item.originalPrice * 100).toFixed(2)}%
    </div>}
    <div style={{ padding: 3 }}>
      <Typography style={{ fontSize: 14, lineHeight: '18px', fontWeight: 'bolder', color: 'black' }}>
        {item.name}
      </Typography>
      <div>
        <Rating
          name="star-rating"
          value={4}
          style={{ fontSize: 20 }}
          precision={0.5}
        />
      </div>
      <Typography style={{ color: 'green', fontWeight: 'bold', fontSize: 16 }}>
        kSH {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        {' '}
        {item.originalPrice != null && parseInt(item.originalPrice) > parseInt(item.price) && <del style={{ color: 'grey', fontSize: 14 }}>{item?.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</del>}
        {' '}
      </Typography>
    </div>
  </Link>
)}

        </div>
      </div>
      {productList.length > productsPerPage && (
        <Pagination
          productsPerPage={productsPerPage}
          totalProducts={productList.length}
          paginate={paginate}
          currentPage={currentPage}
        />
      )}
      <div style={{ height: 100 }}></div>
      <BottomTabs />
    </div>
  );
}

const Pagination = ({ productsPerPage, totalProducts, paginate, currentPage }) => {
  const pageCount = Math.ceil(totalProducts / productsPerPage);

  const handlePageChange = (event, value) => {
    paginate(value);
    window.scrollTo(0, 0);
  };

  return (
    <Stack spacing={2} justifyContent="center" alignItems="center" mt={3}>
      <MuiPagination
        count={pageCount}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        variant="outlined"
        shape="rounded"
      />
    </Stack>
  );
};
