import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Typography, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import { db } from './firebase-config';
import { doc, onSnapshot, setDoc } from '@firebase/firestore';

const initialBrands = [];

const generateRandomNumber = () => {
  return Math.floor(10000 + Math.random() * 90000); // Generates a random 5-digit number
};

const RecursiveTreeView = ({ nodes, addNode, deleteNode, openEditModal, depth = 0 }) => {
  if (!nodes || nodes.length === 0) {
    return null;
  }
useEffect(()=>{
  console.log(nodes)
},[])
  return nodes.map((node) => (
    <TreeItem
      key={node.id}
      itemId={node.id}
      nodeId={node.id}
      label={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography>{node.name}</Typography>
          {/* {depth < 2 && (
            <IconButton
              aria-label="add"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                addNode(node.id);
              }}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          )} */}
          <IconButton
            aria-label="edit"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              openEditModal(node.id, node.name);
            }}
          >
            <EditIcon fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              deleteNode(node.id);
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Box>
      }
    >
      {node.subbrands && node.subbrands.length !== 0 && (
        <RecursiveTreeView
          nodes={node.subbrands}
          addNode={addNode}
          deleteNode={deleteNode}
          openEditModal={openEditModal}
          depth={depth + 1}
        />
      )}
    </TreeItem>
  ));
};

export default function BrandSimpleTree() {
  const [brands, setBrands] = useState(initialBrands);
  const [loading, setLoading] = useState(true);
  const [newBrandName, setNewBrandName] = useState('');
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [editNodeId, setEditNodeId] = useState('');
  const [editNodeName, setEditNodeName] = useState('');
  const [brandModalOpen, setBrandModalOpen] = useState(false);

  const openEditModal = (id, name) => {
    setEditNodeId(id);
    setEditNodeName(name);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setEditNodeId('');
    setEditNodeName('');
  };

  const brandsRef = doc(db, 'brands', 'brandsDoc');

  useEffect(() => {
    const unsubscribe = onSnapshot(brandsRef, (doc) => {
      if (doc.exists()) {
        setBrands(doc.data().brands || initialBrands);
      } else {
        setBrands(initialBrands);
      }
     
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const addRootBrand = async () => {
    const newId = `${generateRandomNumber()}-${new Date().getTime()}`;
    const newName = newBrandName || 'New Brand';
    const newBrands = [...brands, { id: newId, name: newName, subbrands: [] }];
    setBrands(newBrands);
    await writeBrandsToDatabase(newBrands);
    setNewBrandName('');
  };

  const addNode = async (parentId) => {
    const newId = `${generateRandomNumber()}-${new Date().getTime()}`;
    const newName = 'New Subbrand';
    const addSubbrand = (nodes) => {
      return nodes.map((node) => {
        if (node.id === parentId) {
          return {
            ...node,
            subbrands: [...(node.subbrands || []), { id: newId, name: newName }],
          };
        } else if (node.subbrands) {
          return {
            ...node,
            subbrands: addSubbrand(node.subbrands),
          };
        }
        return node;
      });
    };

    const newBrands = addSubbrand(brands);
    setBrands(newBrands);
    await writeBrandsToDatabase(newBrands);
    console.log(brands);
  };

  const deleteNode = async (nodeId) => {
    const removeNode = (nodes) => {
      return nodes
        .filter((node) => node.id !== nodeId)
        .map((node) => {
          if (node.subbrands) {
            return { ...node, subbrands: removeNode(node.subbrands) };
          }
          return node;
        });
    };

    const updatedBrands = removeNode(brands);
    setBrands(updatedBrands);
    await writeBrandsToDatabase(updatedBrands);
  };

  const editNode = async (id, newName) => {
    const updateNode = (nodes) => {
      return nodes.map((node) => {
        if (node.id === id) {
          return { ...node, name: newName };
        } else if (node.subbrands) {
          return { ...node, subbrands: updateNode(node.subbrands) };
        }
        return node;
      });
    };

    const updatedBrands = updateNode(brands);
    setBrands(updatedBrands);
    await writeBrandsToDatabase(updatedBrands);
  };

  const saveEditedNode = async () => {
    const updateNode = (nodes) => {
      return nodes.map((node) => {
        if (node.id === editNodeId) {
          return { ...node, name: editNodeName };
        } else if (node.subbrands) {
          return { ...node, subbrands: updateNode(node.subbrands) };
        }
        return node;
      });
    };

    const updatedBrands = updateNode(brands);
    setBrands(updatedBrands);
    await writeBrandsToDatabase(updatedBrands);

    closeEditModal();
  };

  const writeBrandsToDatabase = async (brands) => {
    try {
      await setDoc(brandsRef, { brands });
      console.log('Brands successfully written to Firestore!');
    } catch (error) {
      console.error('Error writing brands to Firestore: ', error);
    }
  };

  function transformToObjects(names) {
    return names.map(name => ({
      id: generateId(),
      name
    }));
  }
  
  function generateId() {
    return Math.random().toString(36).substr(2, 9); // Generates a random ID
  }
  
  const transformedBrands = transformToObjects([
  ]);


  
  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{}}>
      {/* <button onClick={async()=>{


const brandsRef = doc(db, 'brands', 'brandsDoc');
try {
  await setDoc(brandsRef, { brands: transformedBrands });
  console.log('Brands added successfully');
} catch (error) {
  console.error('Error adding brands:', error);
}
      }}>test</button> */}
      <Button 
        variant="contained"
        color="primary"
        size='small'
        onClick={() => setBrandModalOpen(true)}
        sx={{ marginBottom: 2 }}
      >
        Edit Brands
      </Button>
      <Dialog open={brandModalOpen} onClose={() => setBrandModalOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Edit Brands</DialogTitle>
        <DialogContent>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={addRootBrand}
            sx={{ marginBottom: 2 }}
          >
            Add Root Brands
          </Button>
          <SimpleTreeView>
            <RecursiveTreeView nodes={brands} addNode={addNode} deleteNode={deleteNode} openEditModal={openEditModal} />
          </SimpleTreeView>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setBrandModalOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editModalOpen} onClose={closeEditModal}>
        <DialogTitle>Edit Brand</DialogTitle>
        <DialogContent>
          <TextField
            label="Brand Name"
            value={editNodeName}
            onChange={(e) => setEditNodeName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeEditModal} color="primary">
            Cancel
          </Button>
          <Button onClick={saveEditedNode} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
