import '../App.css';
import React, { useEffect, useState } from 'react';
import CategoryBanner from '../CategoryBanner';
import NavCart from './navCart';
import MenuItem from './menuItem';
// import Search from './search';
import { Button, Typography } from '@mui/material';
import { Rating } from '@mui/material';
import Swiper from '../swiper.js';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { db, storage } from './firebase-config';
import {
  collection,limit,
  getDocs,
  query,
  doc,
  where,
  addDoc,
  deleteDoc,
  updateDoc,
  orderBy,
  startAfter,
  startAt,
  endAt,
} from '@firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from '@firebase/storage';
import Navbar from './navbar.js';
import { Link } from 'react-router-dom';
import BottomTabs from './BottomTabs';
import Footer from './footer.js';

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

function App() {

 const [productList, setProductList] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const productsPerPage = 20;
  const [topProducts, setTopProducts] = useState([]);
  const fetchProducts = async (loadMore = false) => {
    setLoading(true);
    let productQuery;

    if (loadMore && lastVisible) {
      productQuery = query(
        collection(db, 'products'),
        startAfter(lastVisible),
        limit(productsPerPage)
      );
    } else {
      productQuery = query(collection(db, 'products'), limit(productsPerPage));
    }

    const data = await getDocs(productQuery);
    const products = data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    const shuffledProducts = shuffleArray(products);

    if (loadMore) {
      setProductList((prev) => [...prev, ...shuffledProducts]);
    } else {
      setProductList(shuffledProducts);
    }

    setLastVisible(data.docs[data.docs.length - 1]);
    setLoading(false);
    const topTenProducts = shuffledProducts
    .map(product => ({
      ...product,
      percentageDifference: ((product.originalPrice - product.price) / product.originalPrice) * 100
    }))
    .sort((a, b) => b.percentageDifference - a.percentageDifference)
    .slice(0, 10);
console.log(topTenProducts)
  // Set the state with the top products
  setTopProducts(topTenProducts);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const loadMoreProducts = () => {
    fetchProducts(true);
  };

  const products = [
    {
      name: 'Grand Slam Indoor Of Show Jumping Novel',
      url:
        'https://beta.apinouthemes.com/uploads/e2132e11d3c34da89511593297697922.jpg',
      price: '1200',
      discount: '1000',
    },
    {
      name: 'Sound Intone I65 Earphone White Version',
      url:
        'https://beta.apinouthemes.com/uploads/b097fdfb8b9d40fca83de9180de5a7ab.jpg',
      price: '1500',
      discount: '1200',
    },
    {
      name: 'Korea Long Sofa Fabric In Blue Navy Color',
      url:
        'https://beta.apinouthemes.com/uploads/04ec8d58423647c98d6dbd3544c1c355.jpg',
      price: '2000',
      discount: '1600',
    },
    {
      name: 'Unero Military Classical Backpack',
      url:
        'https://beta.apinouthemes.com/uploads/1e1f0f78e33d4ce7828d21465e84da7d.jpg',
      price: '1200',
      discount: '1200',
    },
  ];

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  // const [search, setSearch] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      // Update the screen dimensions when the window is resized
      setScreenWidth(window.innerWidth);
      setScreenHeight(window.innerHeight);
    };

    // Add an event listener to the window's resize event
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [search,setSearch]=useState('')


  return (
    <div style={{minHeight:'100vh'}}>
    <Navbar  setSearch2={setSearch} search2={search}/>
      <CategoryBanner />
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div style={{ width: '100%', maxWidth: 700, display: 'flex', justifyContent: 'center' }}>
          <Swiper screenWidth={screenWidth} topProducts={topProducts} />
        </div>
      </div>

      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            width: '100%',
            maxWidth: 1200,
            border: '1px solid #e5e5e5',
            margin: 5,
            marginTop: 10,
            marginBottom: 20,
          }}
        >
    <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
  <img 
    style={{width: '100%', maxHeight: '250px', objectFit: 'cover'}} 
    src='https://assets.dragonmart.ae//pictures/0103296_DragonMart_categorylisting_computer&electronics_1of3.jpeg' 
    alt='Computer & Electronics'
  />
</div>


          <div style={{ height: 50, backgroundColor: '#f8f8f8', paddingLeft: 20 }}>
            <Typography variant="h5" style={{ lineHeight: '50px' }}>
              Flash Sale
            </Typography>
          </div>
         
          <div
            className="topcategory"
            style={{
              display: 'flex',
              maxWidth: '100vw',
              overflowX: 'scroll',
              justifyContent: 'center',
              flexDirection: 'row',
              paddingTop: 10,
            }}
          >
                {productList.slice(15, 19).map((item) => (
              <Link target="_blank" rel="noopener noreferrer" to={`../../product/${item.id}/${item.name.trim().replaceAll("-", " ").replaceAll(/\s+/g, " ").replaceAll(" ", "-").split('/')[0]}`} key={item.id} style={{ width: '48%', textDecoration: 'none', maxWidth: 200, minWidth: 170, margin: 3, border: '0.3px solid #e5e5e5', backgroundColor: '#f8f8f8', position: 'relative', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <img src={item?.images[0]} style={{ maxHeight: 200, width: '25%', maxWidth: 200, minWidth: 170 }} />
                </div>
                {/* <div
                  style={{
                    padding: 5,
                    fontSize: 14,
                    borderRadius: 10,
                    backgroundColor: 'red',
                    color: 'white',
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: 10,
                    right: 5,
                    zIndex: 10,
                  }}
                >
                  -29%
                </div> */}
                <div style={{ padding: 3 }}>
      <Typography style={{ fontSize: 14, lineHeight: '18px', fontWeight: 'bolder', color: 'black' }}>
        {item.name}
      </Typography>
      <div>
        <Rating
          name="star-rating"
          value={4}
          style={{ fontSize: 20 }}
          precision={0.5}
        />
      </div>
      <Typography style={{ color: 'green', fontWeight: 'bold', fontSize: 16 }}>
        kSH {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        {' '}
        {item.originalPrice != null && parseInt(item.originalPrice) > parseInt(item.price) && <del style={{ color: 'grey', fontSize: 14 }}>{item?.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</del>}
        {' '}
      </Typography>
    </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
 <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
  <img 
    style={{width: '100%',maxWidth:1000, maxHeight: '250px', objectFit: 'cover'}} 
    src='https://images-eu.ssl-images-amazon.com/images/G/40/Electronics/Store/kamja/ZeroGV/zerogv_750x250_en.jpg' 
    alt='Computer & Electronics'
  />
</div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <div
          style={{
            width: '100%',
            maxWidth: 1200,
            border: '1px solid #e5e5e5',
            margin: 5,
            marginTop: 10,
            marginBottom: 20,
          }}
        >
          <div style={{ height: 50, backgroundColor: '#f8f8f8', paddingLeft: 20 }}>
            <Typography variant="h5" style={{ lineHeight: '50px' }}>
              Popular Products
            </Typography>
          </div>
          <div
            className="topcategory"
            style={{
              display: 'flex',
              maxWidth: '100vw',
              overflowX: 'scroll',
              justifyContent: 'center',
              flexDirection: 'row',
              paddingTop: 10,
            }}
          >
            {productList.slice(10, 14).map((item) => (
             <Link target="_blank" rel="noopener noreferrer" to={`../../product/${item.id}/${item.name.trim().replaceAll("-", " ").replaceAll(/\s+/g, " ").replaceAll(" ", "-").split('/')[0]}`} key={item.id} style={{ width: '48%', textDecoration: 'none', maxWidth: 200, minWidth: 170, margin: 3, border: '0.3px solid #e5e5e5', backgroundColor: '#f8f8f8', position: 'relative', display: 'flex', flexDirection: 'column' }}>
              
                <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <img src={item?.images[0]} style={{ maxHeight: 200, width: '25%', maxWidth: 200, minWidth: 170 }} />
                </div>
                {/* <div
                  style={{
                    padding: 5,
                    fontSize: 14,
                    borderRadius: 10,
                    backgroundColor: 'red',
                    color: 'white',
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: 10,
                    right: 5,
                    zIndex: 10,
                  }}
                >
                  -29%
                </div> */}
                <div style={{ padding: 3 }}>
      <Typography style={{ fontSize: 14, lineHeight: '18px', fontWeight: 'bolder', color: 'black' }}>
        {item.name}
      </Typography>
      <div>
        <Rating
          name="star-rating"
          value={4}
          style={{ fontSize: 20 }}
          precision={0.5}
        />
      </div>
      <Typography style={{ color: 'green', fontWeight: 'bold', fontSize: 16 }}>
        kSH {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        {' '}
        {item.originalPrice != null && parseInt(item.originalPrice) > parseInt(item.price) && <del style={{ color: 'grey', fontSize: 14 }}>{item?.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</del>}
        {' '}
      </Typography>
    </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
  <img 
    style={{width: '100%',maxWidth:1000, maxHeight: '250px', objectFit: 'cover'}} 
    src='https://www.sathya.store/img/category/xaTgW6WXP8SsUFMG.jpg' 
    alt='Computer & Electronics'
  />
</div>
<div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
<div style={{ height: 50,width:"100%",maxWidth:1200, backgroundColor: '#f8f8f8', paddingLeft: 20 }}>
            <Typography variant="h6" style={{ lineHeight: '50px' }}>
            Other Products we offer
            </Typography>
            </div></div>
      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around' }}>
        <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', maxWidth: 1200 }}>
       
              {productList.map(item =>
  <Link target="_blank" rel="noopener noreferrer" to={`../../product/${item.id}/${item.name.trim().replaceAll("-", " ").replaceAll(/\s+/g, " ").replaceAll(" ", "-").split('/')[0]}`} key={item.id} style={{ width: '48%', textDecoration: 'none', maxWidth: 200, minWidth: 170, margin: 3, border: '0.3px solid #e5e5e5', backgroundColor: '#f8f8f8', position: 'relative', display: 'flex', flexDirection: 'column' }}>
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', minHeight: 170 }}>
      <img src={item?.images[0]} style={{ maxHeight: 200, width: '100%', maxWidth: 200, minWidth: 170, objectFit: 'contain', objectPosition: 'center' }} alt={item.name} />
    </div>
    {((item.originalPrice - item.price) / item.originalPrice * 100) >= 1 && <div style={{ padding: 3, fontSize: 12, borderRadius: 10, backgroundColor: 'red', color: 'white', opacity: 0.8, fontWeight: 'bold', position: 'absolute', top: 10, right: 5, zIndex: 10 }}>
      {((item.originalPrice - item.price) / item.originalPrice * 100).toFixed(2)}%
    </div>}
    <div style={{ padding: 3 }}>
      <Typography style={{ fontSize: 14, lineHeight: '18px', fontWeight: 'bolder', color: 'black' }}>
        {item.name}
      </Typography>
      <div>
        <Rating
          name="star-rating"
          value={4}
          style={{ fontSize: 20 }}
          precision={0.5}
        />
      </div>
      <Typography style={{ color: 'green', fontWeight: 'bold', fontSize: 16 }}>
        kSH {item.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        {' '}
        {item.originalPrice != null && parseInt(item.originalPrice) > parseInt(item.price) && <del style={{ color: 'grey', fontSize: 14 }}>{item?.originalPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</del>}
        {' '}
      </Typography>
    </div>
  </Link>
)}
        </div>
      </div>

      {loading ? (
        <div style={{ textAlign: 'center', marginTop: 20 }}>Loading...</div>
      ) : (
        <div style={{ textAlign: 'center', marginTop: 20 }}>
          <Button size='small' variant='outlined' onClick={loadMoreProducts} style={{ cursor: 'pointer' }}>
            Load More
          </Button>
        </div>
      )}


      <div style={{ height: 100 }}></div>
<Footer/>
      <BottomTabs />

    </div>
  );
}

export default App;
