import React, { useEffect, useState } from 'react';
import Navbar from './navbar.js';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ReactHtmlParser from 'react-html-parser';
import { doc, getDoc } from '@firebase/firestore';
import { db } from './firebase-config';
import { Helmet } from 'react-helmet';
import { useDispatch ,useSelector} from 'react-redux';
import { addToCart, incrementQuantity, removeFromCart,decrementQuantity} from '../redux/features/cart/cartSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from './footer.js';

export default function Products() {
  const { id } = useParams();
  const cartItems = useSelector(state => state.cart.items);
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const isInCart = cartItems.some(item => item.name === product?.name);

  const [selectedImage, setSelectedImage] = useState('https://dev-to-uploads.s3.amazonaws.com/i/mrvsmk2pl3l8fwocbfhy.gif');
  const dispatch = useDispatch();

  const handleAddToCart = () => {
    if (!isInCart) {
      dispatch(addToCart({ name: product?.name, image: selectedImage, link: window.location.href, quantity }));
      toast.success(`Added ${product?.name} to cart!`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      setQuantity(1); // Reset quantity after adding to cart
    } else {
      dispatch(removeFromCart({ name: product?.name }));
      toast.info(`Removed ${product?.name} from cart.`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }// Reset quantity after adding to cart
  };
  const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleDecreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  

  useEffect(() => {
    const getProduct = async () => {
      const productRef = doc(db, 'products', id);
      const productSnap = await getDoc(productRef);

      if (productSnap.exists()) {
        const productData = { ...productSnap.data(), id: productSnap.id };
        setProduct(productData);
        setSelectedImage(productData.images?.[0] || '');
        console.log(productData);
      } else {
        console.log(`No such document with id ${id}!`);
      }
    };
    getProduct();
  }, [id]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
  };
useEffect(()=>{
window.scroll(0,0)
},[])
const [search,setSearch]=useState('')
  return (
    <div style={{minHeight:'100vh'}}>
       <ToastContainer />
         <Helmet>
        <meta name="description" content />
        <meta name="keywords" content="Kenya online Shopping, discount shopping, fast delivery, mobile phones, laptop, women’s fashion, men’s fashion, Samsung, OPPO, Vivo, Huawei, Apple, shoes, home appliance, Jumia, Black Friday, bags, Kilimall" />
        {/* <meta name="author" content="Your Name" /> */}
        <meta property="og:title" content={product?.name} />
        <meta property="og:description" content />
        <meta property="og:image" content={selectedImage} />
        {/* <meta name="viewport" content="width=device-width, initial-scale=1" /> */}
      </Helmet>
      <Navbar setSearch2={setSearch} search2={search} />
      <div style={{ width: '100%', display: 'flex', flexWrap: 'wrap', maxWidth: '100vw', overflow: 'hidden' }}>
        <div style={{ flex: 1, padding: 5, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <div
            style={{
              flex: 1,
              padding: 10,
              maxHeight: '60vh',
              minHeight: 200,
              height: '100vw',
              maxWidth: '100vw',
              minWidth: 340,
              backgroundColor: '#f8f8f8',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            
            <img
              src={selectedImage}
              alt="Product"
              style={{
                maxWidth: '100%',
                maxHeight: '100%',
                display: 'block', // Remove any extra spacing below the image
                margin: '0 auto',
              }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
            {product.images?.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Thumbnail ${index}`}
                onClick={() => handleImageClick(image)}
                style={{
                  width: 50,
                  height: 50,
                  margin: 5,
                  cursor: 'pointer',
                  border: selectedImage === image ? '2px solid #000' : '2px solid transparent',
                }}
              />
            ))}
          </div>
        </div>
        <div style={{ flex: 1, minWidth: 340, padding: 10 }}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }}>
            {product?.name}
          </Typography>
          <Typography variant="h6" sx={{fontWeight:'bold',color:"#43A047"}}>
                Price:Ksh {' '}{product?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              </Typography>
              <Typography variant="body1" color="">
                Original Price: <del>{product?.originalPrice?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</del>
              </Typography>
              <Typography variant="body1" color="">
                Category: {' '}{product?.category?.toString()}
              </Typography>
              <div style={{ width: '100%', justifyContent: 'space-between', display: 'flex', padding: 0, paddingTop: 20, maxWidth: '100vw',flexWrap:"wrap" }}>
       {isInCart&& <div style={{ margin: 5 ,minWidth:150}}>
          <Button variant="outlined" size='small' onClick={()=>{ dispatch(decrementQuantity({ name: product.name }))}} disabled={cartItems?.filter(item => item.name === product?.name)[0]?.quantity === 1}
          >
            <RemoveIcon />
          </Button>
          <span style={{ padding: 10 }}>{isInCart && cartItems.filter(item => item.name === product?.name)[0].quantity}</span>
          <Button variant="outlined" size='small'  onClick={()=>{ dispatch(incrementQuantity({ name: product.name }))}}
          >
            <AddIcon />
          </Button>
        </div>}
        <div style={{flex:1}}></div>
        <div style={{minWidth:150}}>
        <Button 
              variant={isInCart ? "outlined" : "contained"}  size='small' 
              color={isInCart ? "error" : "primary"} 
              onClick={handleAddToCart} 
              style={{ margin: 5 }}
            >
              {isInCart ? "Remove from Cart" : "Add to Cart"}
            </Button></div>
      </div> <br/> 
                  <Typography variant="subtitle">
                        <u>
                          Description
                        </u>
                        </Typography> 
          {/* */}
          <div style={{ paddingTop: 10 }}>
            <Typography variant="h7">Quantity: {product.quantity}</Typography>
          </div>
          <div style={{ paddingTop: 15 }}>
            <Typography variant="h7">Color: {product.color}</Typography>
          </div>
          <div style={{ paddingTop: 15 }}>
            <Typography variant="h7">Size: {product.size}</Typography>
          </div>
 
          <br />
          {/* <WhatsAppButton phoneNumber="+254727216469" message={`I want this product ${product.name}`} /> */}
               <div style={{ paddingTop: 20 }}>
            <Typography variant="h7">{ReactHtmlParser(product.description)}</Typography>
          </div>  </div>
        <div style={{ width: 300 }}>
          {/* <Typography variant='h6'>Delivery</Typography> */}
        </div>
      </div> <div style={{padding:10,display: "flex",justifyContent:"center",width:"100%"}}>
        <div style={{maxWidth:"1360px",width:"100%"}}>

     
<Typography variant='h5' style={{maxWidth:'100vw'}}>
            Other Details
          </Typography>
    
          {ReactHtmlParser(product.otherDetail) }
          </div>   </div>
      <div style={{ minHeight: '100px' }}></div>
      {/* <BottomTabs/> */}
     <Footer/>

    </div>
  );
}

const WhatsAppButton = ({ phoneNumber, message }) => {
  // Format the phone number and message into the WhatsApp URL
  const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;

  return (
    <Button
      variant="contained"
      color="primary"
      href={whatsappURL}
      target="_blank"
      rel="noopener noreferrer"
      style={{ backgroundColor: '#25D366', color: 'white' }}
    >
      Chat on WhatsApp
    </Button>
  );
};
