// import logo from './logo.svg';
import './App.css';
import React,{useEffect,useState} from 'react';

  import { BrowserRouter,Routes,Route,Link, useNavigate } from 'react-router-dom';
  import Product from './components/products'
  import Home from './components/home'
  import Admin from './components/admin'
import Category  from './components/category'
import {
  Home as HomeIcon,
  List as ListIcon,
  Person as PersonIcon,
  ShoppingCart as ShoppingCartIcon,
  Chat as ChatIcon,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import Cart from './components/cart';
import OrdersPage from './components/orders';
function App() {

  return (
    <div >
<BrowserRouter>
<Routes>
  <Route path='/' element={<Home/>}  />
  <Route path='/product/:id/:slug' element={<Product/>}  />
  <Route path='/category' element={<Category/>}  />
  <Route path='/cart' element={<Cart/>}  />
  <Route path='/admin' element={<Admin/>}  />
  <Route path='/orders' element={<OrdersPage/>}  />
  <Route path='*' element={<Home />} />
</Routes>

</BrowserRouter>
  
    </div>
   
  );
}

export default App;
