import { IconButton, Badge } from '@mui/material';
import { ShoppingCart, Menu } from '@mui/icons-material';
import MenuList from '../menu'
import React, { useEffect,useState, useRef } from 'react';
import SimpleListMenu from './menuOption.js'

function App(props) {
  const myDivRef = useRef(null);
const [menu,setMenu]=useState(false)
  useEffect(() => {
    function handleClickOutside(event) {
      if (myDivRef.current && !myDivRef.current.contains(event.target)) {
        // Click occurred outside the div
        console.log('Click occurred outside the div',menu);
        if(menu==true){
                 setMenu(false);
        }
 
        // Add your function to run here
      }
    }

    // Attach the event listener to the document
    document.addEventListener('click', handleClickOutside);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [menu]);
  return (
    <div ref={myDivRef} 
    // onClick={()=>{props.setSearch(false)}}
       style={{position:'relative'}}>

<SimpleListMenu/>
  
   {/* {menu &&  <div style={{minWidth:300,height:500,backgroundColor:'white',position:'absolute',
    top:60,left:0,border:'0.5px solid #e5e5e5',zIndex:10,overflowY:'scroll',overflowX:'hidden',
    boxShadow: "0 2px 5px 0 rgba(0,0,0,.16), 0 2px 5px 0 rgba(0,0,0,.23)",
    fontSize:12,textDecoration:'none',
    }}>

        <MenuList/>
  

    </div>} */}
    </div>
  );
}

export default App;
