import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector, useDispatch } from 'react-redux';
import { incrementQuantity, decrementQuantity, removeFromCart } from '../redux/features/cart/cartSlice';
import { doc, setDoc,addDoc ,collection } from '@firebase/firestore';
import {db} from './firebase-config'; // Import your Firestore instance
import BottomTabs from './BottomTabs';
import Navbar from './navbar.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { clearCart } from '../redux/features/cart/cartSlice.js';
export default function Cart() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerName, setCustomerName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [location, setLocation] = useState('');

  const items = useSelector((state) => state.cart.items);
  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

// Inside handleCheckout function
const handleCheckout = async () => {
  try {
    // Prepare data for Firebase submission
    const orderData = {
      customerName,
      phoneNumber,
      location,
      timestamp: new Date() ,
      items: items.map(item => ({ name: item.name, quantity: item.quantity }))
    };

    // Submit to Firebase Firestore collection 'orders'
    const docRef = await addDoc(collection(db, 'orders'), orderData);

    console.log('Order submitted successfully with ID:', docRef.id);

    // Show success toast
    toast.success('Order submitted successfully!', {
      position:'top-right'
    });

    // Clear the cart
    dispatch(clearCart());

    // Close the modal after successful submission
    setIsModalOpen(false);

  } catch (error) {
    console.error('Error submitting order:', error);
    // Show error toast
    toast.error('Error submitting order. Please try again later.', {
      position:'top-right'
    });
    // Handle error, e.g., show an error message
  }
};

  
  return (
    <div>
       <ToastContainer />
      <Navbar />
      <div style={{ display: 'flex', justifyContent: 'center', padding: '10px' }}>
        <div style={{ width: '100%', maxWidth: '500px' }}>
          <Typography variant='h5' style={{ padding: 10, textAlign: 'center' }}>Cart</Typography>
          {items.length === 0 && (
            <Typography style={{ textAlign: 'center', margin: 10, userSelect: 'none' }}>
              No items in cart
            </Typography>
          )}
          {items.map((item, index) => (
            <MenuItem key={index} style={{ justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'start', width: '100%' }}>
                <img
                  src={item.image}
                  alt={item.name}
                  style={{ marginRight: '10px', width: '50px', height: '50px' }}
                />
                <div style={{ flex: 1 }}>
                  <div style={{ whiteSpace: 'pre-wrap' }}>
                    <Typography variant='body2' style={{ fontSize: 12 }}>
                      {item.name}
                    </Typography>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={() => dispatch(decrementQuantity({ name: item.name }))}>
                      <RemoveIcon />
                    </IconButton>
                    <span style={{ margin: '0 10px' }}>{item.quantity}</span>
                    <IconButton onClick={() => dispatch(incrementQuantity({ name: item.name }))}>
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
                <IconButton onClick={() => dispatch(removeFromCart({ name: item.name }))}>
                  <CloseIcon />
                </IconButton>
              </div>
            </MenuItem>
          ))}
          {items.length > 0 && (
            <div style={{ padding: 5 }}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  background: 'linear-gradient(45deg, #405de6 30%, #5851db 90%)',
                  color: 'white',
                  marginTop: '16px',
                }}
                onClick={handleOpenModal}
              >
                Checkout
              </Button>
            </div>
          )}
          <div style={{height:150}}></div>
        </div>
      </div>
      <BottomTabs />

      {/* Modal for entering customer details */}
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          padding: '20px',
          minWidth: '300px',
          maxWidth: '500px',
          borderRadius: '8px',
          outline: 'none',
        }}>
          <Typography variant="h6" id="modal-title" style={{ marginBottom: '10px' }}>Enter Your Details</Typography>
          <TextField
            label="Name"
            fullWidth
            variant="outlined"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="Phone Number"
            fullWidth
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <TextField
            label="Location"
            fullWidth
            variant="outlined"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <Button
            variant="contained"
            onClick={handleCheckout}
            style={{
              background: 'linear-gradient(45deg, #405de6 30%, #5851db 90%)',
              color: 'white',
              marginTop: '16px',
              width: '100%',
            }}
          >
            Confirm Checkout
          </Button>
        </div>
      </Modal>
    </div>
  );
}
