
import React, { useEffect,useState, useRef } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useNavigate, useSearchParams } from 'react-router-dom';


function App({search2,setSearch2}) {
 const navigate=useNavigate();
 const [searchParams, setSearchParams] = useSearchParams();

 const initialSearchTerm = searchParams.get('searchProduct') || '';
 useEffect(()=>{
  if (window.location.pathname=='/' && search2!=''){
  setSearch2(initialSearchTerm)
  }
 },[])
  return (
    <div  style={{ flex: 1, maxWidth: 800 }}>
      <TextField
        variant="outlined"
        fullWidth
value={search2}
onChange={(e)=>{setSearch2(e.target.value);

}}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <div onClick={()=>{

                
  if (window.location.pathname=='/' && search2!=''){
    navigate('/category?searchProduct='+search2)
  }
              }} className="search-button rounded-right">Search</div>
            </InputAdornment>
          ),
          sx: {
            borderRadius: '50px', // Adjust the value to control the roundness
            backgroundColor: 'white',
          },
          style: { paddingRight: '0',height:39 }, // Remove padding from the input field
        }}
      />
    </div>
  );
}

export default App;
