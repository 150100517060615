import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { IconButton, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { useNavigate } from 'react-router-dom';

 const options = [
  { label: "TVs", iconClass: "fas fa-tv",
    children: [
      {
        title: 'TVs by Brand',
        children: ['Von', 'LG', 'Samsung', 'Hisense'],
      },
      {
        title: 'TVs by Size',
        children: ["24'' to 32''", "40'' to 49''", "50'' to 59''", "60'' to 70''", "75'' & Above"],
      },
      {
        title: 'TVs By Feature',
        children: ['Digital', 'Smart', 'HD', 'FHD', '4K UHD', 'QLED', 'QNED', 'OLED', 'Nanocell', '8K'],
      },
      {
        title: 'Projectors',
        children: ['Portable'],
      },
      {
        title: 'Accessories',
        children: ['TV Brackets'],
      },
    ],
  },
  { label: "Audio", iconClass: "fas fa-volume-up",
    children: [
      {
        title: 'Audio Type',
        children: ['Soundbars', 'Noise Cancelling', 'Home Theatre Systems', 'Subwoofers', 'Earbuds', 'Gaming', 'Headphones', 'Portable Speakers'],
      },
      {
        title: 'Audio By Brand',
        children: ['LG', 'Samsung', 'Von', 'JBL', 'Oraimo', 'Sony', 'Belkin', 'Hisense', 'Anker', 'Soundcore', 'Harman Kardon'],
      },
    ],
  },
  { label: "Fridges & Freezers", iconClass: "fas fa-snowflake",
    children: [
      {
        title: 'Fridges',
        children: ['Bespoke', 'Solar', 'InstaView', 'Mini', 'Single Door', 'Double Door (Top Mount Freezer)', 'Combination (Bottom Mount Freezer)', 'Side by Side', 'Upright'],
      },
      {
        title: 'Freezers',
        children: ['Chest', 'Upright'],
      },
      {
        title: 'Chillers',
        children: ['Wine Coolers', 'Vertical Coolers'],
      },
    ],
  },
  { label: "Washers & Dryers", iconClass: "fas fa-tshirt",
    children: [
      {
        title: 'Washing Machines',
        children: ['Twin Tubs', 'Top Loaders', 'Front Loaders', 'Washer & Dryers', 'Commercial'],
      },
      {
        title: 'Washers & Dryers',
        children: ['WashTower', 'Front Loaders'],
      },
      {
        title: 'Dryers',
        children: ['Condensing', 'Commercial'],
      },
      {
        title: 'Dishwasher',
        children: ['12 Place Setting', '14 Place Setting'],
      },
      {
        title: 'Accessories',
        children: ['Cleaning Products'],
      },
    ],
  },
  { label: "Cookers & Ovens", iconClass: "fas fa-utensils",
    children: [
      {
        title: 'Free Standing Cookers',
        children: ['Electric Oven', 'Gas Oven'],
      },
      {
        title: 'Size (Width)',
        children: ['50 - 55cm', '56 - 60cm', '65 - 90cm', '100cm'],
      },
      {
        title: 'Burners',
        children: ['4 Gas', '5 Gas', '6 Gas', '4 Electric', '3 Gas + 1 Electric', '4 Gas + 2 Electric'],
      },
      {
        title: 'Table Top Cookers',
        children: ['Gas Burners', 'Gas + Electric Burners', 'Electric Burners'],
      },
      {
        title: 'Ovens',
        children: ['20 - 40L', '70 - 90L'],
      },
      {
        title: 'Microwave Ovens',
        children: ['Standard', 'Oven & Grill', 'Oven, Grill & Convection Combos'],
      },
      {
        title: 'Cleaning Products',
        children: [],
      },
    ],
  },
  { label: "Kitchen & Small Home Appliances", iconClass: "fas fa-blender",
    children: [
      {
        title: 'Kitchen Essentials',
        children: ['Coffee Makers', 'Kettles', 'Toasters', 'Blenders', 'Juicers', 'Sandwich Makers', 'Waffle Makers'],
      },
      {
        title: 'Small Cooking Appliances',
        children: ['Air Fryers', 'Deep Fryers', 'Rice Cookers', 'Slow Cookers', 'Pressure Cookers', 'Popcorn Poppers', 'Egg Cookers', 'Barbecue Grills'],
      },
      {
        title: 'Food Preparation',
        children: ['Hand Mixers', 'Food Processors', 'Food Choppers & Grinders', 'Kitchen Utensils', 'Hand Blenders', 'Meat Mincers', 'Stand Mixers', 'Kitchen Machines', 'Kitchen Weighing Scales'],
      },
      {
        title: 'Baking & Cookware',
        children: ['Bread Makers'],
      },
      {
        title: 'Water Dispensers',
        children: ['Bottom Loading', 'Touchless Dispensers', 'Portable Dispensers', 'Compressor Cooling', 'Electric Cooling', 'Water Purifiers', 'Hot & Normal', 'Normal (Non-Electric)'],
      },
      {
        title: 'Garment Care',
        children: ['Dry Irons', 'Steam Irons', 'Steam Stations', 'Garment Steamers', 'Ironing Boards'],
      },
      {
        title: 'Fans',
        children: ['Air Coolers', 'Air Purifiers', 'Standing Fans', 'Wall Fans'],
      },
    ],
  },
  { label: "Built in Appliances", iconClass: "fas fa-building",
    children: [
      { title: 'Ovens', children: [] },
      { title: 'Hobs', children: [] },
      { title: 'Microwaves', children: [] },
      { title: 'Hoods', children: [] },
      { title: 'Dishwashers', children: [] },
      { title: 'Fridges & Freezers', children: [] },
      { title: 'Warmer Drawer', children: [] },
    ],
  },
  { label: "AC's, Fans & Heaters", iconClass: "fas fa-fan",
    children: [
      {
        title: 'Air Conditioners',
        children: ['Split Air Conditioners', 'Portable Air Conditioners', 'Cassette Air Conditioners', 'Ceiling Concealed Duct Air Conditioners'],
      },
      {
        title: 'Fans',
        children: ['Standing Fans', 'Air Coolers', 'Air Purifiers', 'Wall Fans'],
      },
      {
        title: 'Heaters',
        children: ['Ceramic Heaters', 'Convection Heaters', 'Fan Heaters', 'Oil Radiator Heaters', 'Quartz Heaters'],
      },
    ],
  },
  { label: "Health & Personal Care", iconClass: "fas fa-heartbeat",
    children: [
      {
        title: 'Health',
        children: ['Thermometers', 'Weighing Scales'],
      },
      {
        title: 'Personal Care',
        children: ['Hair Care', 'Shave & Hair Removal'],
      },
      {
        title: 'Weighing Scales',
        children: ['Medical'],
      },
    ],
  },
  { label: "Accessories", iconClass: "fas fa-plug",
    children: [
      {
        title: 'TV & Audio',
        children: ['Microphones', 'TV Brackets', 'Voltage Protectors', 'Cables'],
      },
      {
        title: 'Mobile Accessories',
        children: ['Car Chargers', 'Power Adapters', 'Power Banks', 'Hub Adapters', 'Charging Cables', 'Wireless Stand Chargers'],
      },
      {
        title: 'Fridges & Freezers',
        children: ['Voltage Protectors', 'Cleaning Products'],
      },
      {
        title: 'Washers & Dryers',
        children: ['Voltage Protectors', 'Cleaning Products'],
      },
    ],
  },
  { label: "Home Cleaning", iconClass: "fas fa-broom",
    children: [
      {
        title: 'Vacuum Cleaners',
        children: ['Robovac', 'Handheld', 'Canister Type'],
      },
    ],
  },
  { label: "Phones & Tablets", iconClass: "fas fa-mobile-alt",
    children: [
      {
        title: 'Smart Watches',
        children: ['Oraimo OSW-30', 'Oraimo OSW-34'],
      },
      {
        title: 'Samsung Z series',
        children: ['Samsung Z Fold 5 - 512GB', 'Samsung Z Flip 5 - 256GB'],
      },
      {
        title: 'Samsung A Series',
        children: ['Samsung A05', 'Samsung A04', 'Samsung A34 5G', 'Samsung A14 4GB', 'Samsung A24 4GB', 'Samsung A14 5G', 'Samsung A04S'],
      },
      {
        title: 'Samsung S Series',
        children: ['Samsung S23 Ultra', 'Samsung S23', 'Samsung S23 Plus'],
      },
      {
        title: 'Samsung Tablets',
        children: ['Samsung Tab A8 32GB', 'Samsung Tab A8 64GB'],
      },
    ],
  },
  { label: "Mobile & Computer", iconClass: "fas fa-laptop",
    children: [
      {
        title: 'Laptops',
        children: ['HP'],
      },
      {
        title: 'Accessories',
        children: ['Type-C Power Adapter', 'Extension Cable', 'Magsafe Charger'],
      },
    ],
  },
  { label: "Promotions", iconClass: "fas fa-tags",
    children: [
      {
        title: 'Red',
        children: ['LG'],
      },
      {
        title: 'Green',
        children: ['Samsung'],
      },
    ],
  },
];
const menuItemStyle = {
  display: 'flex',
  alignItems: 'center', // Center icon and text vertically
};

const iconStyle = {
  marginRight: '10px', // Add spacing between icon and text
};

export default function App() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const open = Boolean(anchorEl);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
const navigate=useNavigate()
  return (
    <div>
      <IconButton onClick={() => setDrawerOpen(true)} sx={{ color: 'black' }}>
        <i className="fas fa-bars"></i>
      </IconButton>

      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)}>
        <div>
        <Typography variant="h6" style={{paddingLeft:10}}>Categories</Typography>
          <List>
            {options.map((option, index) => (
              <ListItem
                button
                key={option.label}
                selected={index === selectedIndex}
                onClick={(event) => {navigate('/category?search='+option.label.split(' ')[0])}}
                style={menuItemStyle}
              >
                <i className={option.iconClass} style={iconStyle}></i> {option.label}
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>

      <main>
        {/* Your main content goes here */}
      </main>
    </div>
  );
}
