import React, { useState } from 'react';

function Menu() {
  // State to keep track of expanded items
  const [expandedItems, setExpandedItems] = useState({});

 
  const toggleItem = (itemId) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [itemId]: !prevState[itemId],
    }));
  };

  // Define your menu data
const menuData =  [
  { text: "Consumer Electronic", icon: "fas fa-tv" },
  { text: "Clothing & Apparel", icon: "fas fa-tshirt" },
  { text: "Home, Garden & Kitchen", icon: "fas fa-home" },
  { text: "Health & Beauty", icon: "fas fa-heart" },
  { text: "Jewelry & Watches", icon: "fas fa-gem" },
  { text: "Computer & Technology", icon: "fas fa-laptop" },
  { text: "Babies & Moms", icon: "fas fa-baby" },
  { text: "Sport & Outdoor", icon: "fas fa-futbol" },
  { text: "Phones & Accessories", icon: "fas fa-mobile-alt" },
  { text: "Books & Office", icon: "fas fa-book" },
  { text: "Cars & Motocycles", icon: "fas fa-car" },
  { text: "Home Improvements", icon: "fas fa-tools" },
  { text: "Vouchers & Services", icon: "fas fa-ticket-alt" },
];

  return (
    // <div className="ps-section__left">

      <ul className="menu--dropdown" style={{listStyle: 'none', padding: 0}}>
        {menuData.map((menuItem, index) => (
          <li key={index} style={{position:'relative'}}
          className="menu-item" // Add a class to the main list item
          onMouseEnter={() => toggleItem(index)} // Toggle expansion on hover
          onMouseLeave={() => toggleItem(index)} // Toggle back on mouse leave
     
          >


            {menuItem.hasChildren ? (
              <>
                <a href={'../../category?search='+menuItem.text.split('')[0]}
                // onClick={() => toggleItem(index)
                >
                  <i className={menuItem.icon ?? "icon-laundry"}></i>
                  {menuItem.text}

                  {menuItem.hasChildren &&<span style={{marginLeft:10,height:'100%'}}><i class="fa-solid fa-angle-right"></i></span>}
                </a>
                {/* {expandedItems[index] && ( */}
                  <div className="mega-menu" style={{width:499,position:'absolute',right:-499,top:0}}>
                    {menuItem.children.map((sublist, subIndex) => (
                      <div key={subIndex} className="mega-menu__column">
                        <h4>{sublist.text}</h4>
                        <ul className="mega-menu__list">
                          {sublist.children.map((item, itemIndex) => (
                            <li key={itemIndex}>
                              <a href="/shop">{item}</a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
             
              </>
            ) : (
                
              <a href={'../../category?search='+menuItem.text.split('')[0]}>
                      <i className={menuItem.icon ??  "lni lni-add-file"}></i>
                {/* <i className="icon-star"></i> */}
                {menuItem.text}
              </a>
            )}
          </li>
        ))}
      </ul>
    // </div>
  );
}

export default Menu;
