import React from 'react';
import { IconButton, Badge } from '@mui/material';
import { ShoppingCart, AccountCircle } from '@mui/icons-material';
import Cart from './cartItem'
import Account from './accountItems'
const labelStyle = {
  fontSize: '12px', // Adjust the font size as needed
};

function App() {
  return (
    <div>
        {/* <Account/> */}
     

    <Cart/>
    </div>
  );
}

export default App;
