import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import styled from '@emotion/styled'; // Import styled from @emotion/styled
import FacebookIcon from '@mui/icons-material/Facebook'; // Import Facebook icon
import TwitterIcon from '@mui/icons-material/Twitter'; // Import Twitter icon
import InstagramIcon from '@mui/icons-material/Instagram'; // Import Instagram icon

// const FooterContainer = styled.footer`
//   background-color: ${({ theme }) => theme.palette.background.paper};
//   margin-top: ${({ theme }) => theme.spacing(4)}px;
//   padding: ${({ theme }) => theme.spacing(6, 0)};
// `;

const Footer = () => {
  return (
    <div style={{minHeight:160,paddingBottom:40,backgroundColor:"rgba(251, 251, 251,1)",display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center"}}>
<Typography variant='subtitle'>Denfatech Technologies @2024</Typography>
<Typography variant='subtitle'>Nairobi, Kenya</Typography>
<Typography variant='body2'>Platinum Plaza,Tom Mboya street</Typography>
<Typography variant='body2'>Mobile Number - +254727216469</Typography>
    </div>
  );
};

export default Footer;
